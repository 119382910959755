import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { label: "Home", path: "/" },
    { label: "Courses", path: "/courses" },
    { label: "Contact", path: "/contact" },
    { label: "Account", path: "/account" },
  ];

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        background: "black",
        color: "white",
        height: 80,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Toolbar>
        {/* Logo */}
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <IconButton
            edge="start"
            color="inherit"
            component={Link}
            to="/"
            sx={{ padding: 0 }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/CodeToCraft.png`}
              alt="Logo"
              style={{
                maxHeight: "70px",
                width: "auto",
                marginTop: "10px",
                marginLeft: "20px",
              }}
            />
          </IconButton>
        </Box>

        {/* Desktop Navigation */}
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {navItems.map((item) => (
            <Button
              key={item.path}
              component={Link}
              to={item.path}
              sx={{
                color: location.pathname === item.path ? "#FF4081" : "white",
                mx: 2,
                fontWeight: location.pathname === item.path ? "bold" : "normal",
                textTransform: "none",
                transition: "color 0.3s ease, transform 0.3s ease",
                "&:hover": {
                  color: "#FF79B0",
                  transform: "scale(1.1)",
                },
              }}
            >
              {item.label}
            </Button>
          ))}
        </Box>

        {/* Hamburger Menu */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleDrawerToggle}
          aria-label="menu"
          sx={{
            display: { sm: "none" },
            transform: mobileOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/menu.png`}
            alt="Mobile Menu"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </IconButton>
      </Toolbar>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100vw",
            height: "100vh",
            backgroundColor: "#121212",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            opacity: "90%",
          },
        }}
      >
        {/* Close Button Inside Drawer */}
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleDrawerToggle}
          aria-label="close menu"
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            zIndex: 1000,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/menu.png`}
            alt="Close Menu"
            style={{
              width: "30px",
              height: "30px",
              transform: "rotate(180deg)",
              transition: "transform 0.3s ease",
            }}
          />
        </IconButton>

        {/* Navigation Links */}
        <List sx={{ textAlign: "center", width: "100%" }}>
          {navItems.map((item) => (
            <ListItem
              key={item.path}
              button
              component={Link}
              to={item.path}
              onClick={handleDrawerToggle}
              sx={{
                color: location.pathname === item.path ? "#FF4081" : "white",
                fontSize: "1.5rem",
                fontWeight: location.pathname === item.path ? "bold" : "normal",
                textAlign: "center",
                padding: "20px 0",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
}

export default Header;
