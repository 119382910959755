import React from 'react';
import { Box, Typography } from '@mui/material';
import { useParallax } from 'react-scroll-parallax';

const PhilosophySection = () => {
  const { ref } = useParallax({ speed: -10});

  return (
<Box
  ref={ref} // Attach parallax effect
  sx={{
    padding: '50px 20px',
    textAlign: 'center',
    backgroundColor: 'black',
    color: 'white',
    position: 'relative',
    marginBottom: '100px',  // Adjusted space
    marginTop: '100px',  // Adjusted space
  }}
>

      <Typography
        variant="h4"
        sx={{
          color: 'white',
          fontSize: { xs: '1.5rem', sm: '2rem' },
        }}
      >
        Our Philosophy
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: '90%',
          mt: 2,
          fontSize: { xs: '1rem', sm: '1.2rem' },
          color: '#cfd8dc',
        }}
      >
        At CodeToCraft, we believe in the power of education to transform lives. Our mission is to
        empower learners to build real-world applications by mastering modern technologies. We
        emphasize hands-on experience, collaboration, and continuous learning.
      </Typography>
    </Box>
  );
};

export default PhilosophySection;
