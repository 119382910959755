import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection

const PageNotFound = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleGoHome = () => {
    navigate("/"); // Navigate to the home page
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", textAlign: "center" }}>
      <Box sx={{ p: 4, boxShadow: 4, borderRadius: "8px"}}>
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2, color: 'white' }}>
          404 - Page Not Found 😔
        </Typography>
        <Typography variant="h6" sx={{ color: "grey", mb: 3 }}>
          Oops! The page you're looking for doesn't exist. Please check the URL or return to the home page. 🌍
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoHome} sx={{ fontWeight: "bold", padding: "10px 20px" }}>
          Go to Home 🏠
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
