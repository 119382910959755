import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Button,
  Grid,
  createTheme,
  ThemeProvider,
  CardMedia,
  Divider
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { loginWithGoogle } from "../api/codetocraft";

const CLIENT_ID = "346569063370-vf9beii4eb73nejmtol0acua7amie91a.apps.googleusercontent.com";
const BACKEND_API = "https://www.codetocraft.tech/api/auth/google";

const AccountPage = () => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserProfile(decodedToken);

      // Fetch courses if logged in
      fetchCourses(token);
    }
  }, []);

  const fetchCourses = async (token) => {
    try {
      const response = await axios.get("https://www.codetocraft.tech/api/courses", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCourses(response.data);
    } catch (error) {
      console.error("Failed to fetch courses:", error);
    }
  };

  const handleLoginSuccess = async (credentialResponse) => {
    try {
      const googleIdToken = credentialResponse.credential;
      const response = await loginWithGoogle(googleIdToken);

      if (response.status === 200) {
        localStorage.setItem("authToken", response.data.jwt);
        const decodedToken = jwtDecode(response.data.jwt);
        setUserProfile(decodedToken);
        fetchCourses(response.data.jwt);
      } else {
        alert("Failed to log in. Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Login failed. Please check your network or try again later.");
    }
  };

  const handleLoginFailure = (error) => {
    console.error("Login Failed:", error);
    alert("Login Failed. Please try again.");
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#f50057",
      },
      background: {
        default: "#000000",
        paper: "#1c1c1c",
      },
      text: {
        primary: "#ffffff",
        secondary: "#b0bec5",
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <Box
          sx={{
            height: "100vh",
            backgroundColor: "#000000",
            padding: 4,
          }}
        >
          {!userProfile ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Card
                sx={{
                  padding: 4,
                  borderRadius: 4,
                  boxShadow: 3,
                  maxWidth: 400,
                  textAlign: "center",
                  backgroundColor: "#1c1c1c",
                }}
              >
                <CardContent>
                  <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold", color: "white" }}>
                    Welcome Back!
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, color: "#b0bec5" }}>
                    Log in with your Google account to continue.
                  </Typography>
                  <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} />
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Card
                  sx={{
                    padding: 4,
                    borderRadius: 4,
                    boxShadow: 3,
                    backgroundColor: "#1c1c1c",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Avatar
                      src={userProfile.picture}
                      sx={{
                        width: 100,
                        height: 100,
                        mb: 2,
                        margin: "0 auto",
                      }}
                    />
                    <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
                      {userProfile.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#b0bec5", mb: 2 }}>
                      {userProfile.email}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        localStorage.removeItem("authToken");
                        setUserProfile(null);
                        setCourses([]);
                      }}
                    >
                      Log Out
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Divider between the two sections */}
              <Grid item xs={12} md={1}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "2px solid #ccc",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={7}>
                <Box>
                  <Typography variant="h4" sx={{ color: "white", mb: 3 }}>
                    My Courses
                  </Typography>
                  <Grid container spacing={4}>
                    {courses.map((course) => (
                      <Grid item xs={12} sm={6} md={4} key={course.id}>
                        <Link to={`/registration/${course.id}`} style={{ textDecoration: "none" }}>
                          <Card
                            sx={{
                              height: "100%",
                              borderRadius: "15px",
                              boxShadow: 3,
                              transition: "transform 0.3s ease",
                              "&:hover": {
                                transform: "translateY(-10px)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="160"
                              image={course.image}
                              alt={course.title}
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h6" component="div">
                                {course.title}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {course.description}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default AccountPage;
