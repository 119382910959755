import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  CircularProgress,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import { fetchCourses } from "../api/codetocraft";



const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const hasFetched = useRef(false); // Ref to track if API has been called
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (hasFetched.current) return; // Avoid fetching again if already done
        hasFetched.current = true;
        const coursesData = await fetchCourses();
        setCourses(coursesData); // Ensure it's an array
        console.log(coursesData);
      } catch (error) {
        console.error("Error while fetching courses:", error);
        alert("Try Refreshing Page or Browser once.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!Array.isArray(courses) || courses.length === 0) {
    return (
      <Box sx={{ textAlign: "center", py: 5 }}>
        <Typography variant="h5" color="text.secondary">
          No courses available at the moment.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ py: 5, background: "black" }}>
      {/* Courses Section */}
      <Container sx={{ py: 5 }}>
        <Grid container spacing={4}>
          {courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} key={course.id}>
              <Link to={`/registration/${course.id}`} style={{ textDecoration: "none" }}>
                <Card
                  sx={{
                    height: "100%",
                    borderRadius: "15px",
                    boxShadow: 3,
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="160"
                    image={course.image}
                    alt={course.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {course.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {course.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: `url("https://via.placeholder.com/1500x500?text=Learn+with+Us")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          textAlign: "center",
          p: 3,
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
            Discover Your Potential
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Learn from the best courses designed for your success.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CoursesPage;
