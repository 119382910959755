import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Drawer,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuIcon from "@mui/icons-material/Menu";
import { fetchCourseDetails as fetchCourse } from "../api/codetocraft";

const CourseDetails = () => {
  const { courseId } = useParams();
  const [courseDetails, setCourseDetails] = useState(null);
  const [selectedSubtopic, setSelectedSubtopic] = useState(null); // Selected subtopic state
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for mobile drawer
  const [expandedTopics, setExpandedTopics] = useState({}); // State for collapsible topics
  const navigate = useNavigate();
  const hasFetched = useRef(false); // Ref to track if API has been called
  // Fetch course details
  const fetchCourseDetails = async () => {
    try {
      if (hasFetched.current) return; // Avoid fetching again if already done
      hasFetched.current = true;
      const courseDetail = await fetchCourse(courseId);
      setCourseDetails(courseDetail);
    } catch (error) {
      console.error("Error fetching course details:", error);
      if (error.response && error.response.status === 404) {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    fetchCourseDetails();
  }, [courseId]);

  // Handle subtopic click
  const handleSubtopicClick = (subtopic) => {
    setSelectedSubtopic(subtopic);
    setIsDrawerOpen(false); // Close drawer on mobile after selection
  };

  // Handle topic collapse toggle
  const handleTopicToggle = (index) => {
    setExpandedTopics((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  if (!courseDetails) {
    return <Typography>Loading course details...</Typography>;
  }

  const drawerContent = (
    <Box
      sx={{
        width: { xs: 250, sm: 300 },
        backgroundColor: "#f8f9fa",
        height: "100vh",
        p: 2,
        overflowY: "auto", // Makes the drawer content scrollable
        scrollbarWidth: "thin", // For Firefox
        scrollbarColor: "#888 #f8f9fa", // For Firefox
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555",
        },
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        Topics
      </Typography>
      <List>
        {courseDetails.topicDTOS.map((topic, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <ListItem button onClick={() => handleTopicToggle(index)}>
              <ListItemText primary={topic.name} />
              {expandedTopics[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={expandedTopics[index]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {topic.subTopics.map((subtopic, subIndex) => (
                  <ListItem
                    button
                    key={subIndex}
                    onClick={() => handleSubtopicClick(subtopic)}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={subtopic.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Divider sx={{ my: 1 }} />
          </Box>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      {/* Navbar Below Header */}
      <AppBar position="static" color="black">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { md: "none" }, color: "white" }}
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, color: "white" }}>
            Course Topics
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 3 }}>
        <Grid container spacing={4}>
          {/* Topics and Subtopics Section (Left Side or Drawer for Mobile) */}
          <Grid item xs={12} md={4} sx={{ display: { xs: "none", md: "block" } }}>
            <Box
              sx={{
                height: "calc(100vh - 64px)", // Adjust based on your AppBar height
                overflowY: "auto", // Makes the content scrollable
                backgroundColor: "#f8f9fa",
                borderRadius: "8px",
                padding: 2,
                scrollbarWidth: "thin", // For Firefox
                scrollbarColor: "#888 #f8f9fa", // For Firefox
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
              }}
            >
              {drawerContent}
            </Box>
          </Grid>

          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            sx={{ display: { md: "none" } }}
          >
            <Box
              sx={{
                height: "100vh", // Ensure full height for mobile drawer
                overflowY: "auto", // Makes the content scrollable on mobile
              }}
            >
              {drawerContent}
            </Box>
          </Drawer>

          {/* Blog Display Section (Right Side) */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                padding: 3,
                minHeight: "100vh",
              }}
            >
              {selectedSubtopic ? (
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    {selectedSubtopic.name}
                  </Typography>
                  {selectedSubtopic.blogs && selectedSubtopic.blogs.length > 0 ? (
                    selectedSubtopic.blogs.map((blog, index) => (
                      <Box key={index} sx={{ mb: 3 }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "medium", mb: 1 }}
                        >
                          {blog.title}
                        </Typography>
                        <Typography>{blog.description}</Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography>No blogs available for this subtopic.</Typography>
                  )}
                </Box>
              ) : (
                <Typography variant="h5" sx={{ color: "gray" }}>
                  Select a subtopic to view its blogs.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CourseDetails;
