import React from 'react';
import { Box } from '@mui/material';
import { useParallax } from 'react-scroll-parallax';

const ImageSection = () => {
  const { ref } = useParallax({ speed: -10});

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 10,
        background: 'black',
        padding: 4,
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/landing.png`}
        alt="CodeToCraft"
        style={{
          maxWidth: '80%',
          height: 'auto',
          borderRadius: '8px',
          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.5)',
        }}
      />
    </Box>
  );
};

export default ImageSection;
