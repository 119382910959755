import React from "react";

const PrivacyPolicy = () => {
  const containerStyle = {
    padding: "20px",
    // fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    maxWidth: "800px",
    margin: "0 auto",
    color: 'white'
  };

  return (
    <div style={containerStyle}>
      <header>
        <h1>Privacy Policy for CodeToCraft.tech</h1>
      </header>

      <main>
        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to CodeToCraft.tech. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Website and use our services. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Website.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <p>We collect the following types of information:</p>
          <h3>a. Personal Information</h3>
          <p>When you sign up for courses, create an account, or communicate with us, we may collect personal information, such as:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Payment details (processed through secure third-party services)</li>
          </ul>

          <h3>b. Non-Personal Information</h3>
          <p>We may collect non-personal information, such as:</p>
          <ul>
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>Pages visited on the Website</li>
            <li>Time and date of visits</li>
            <li>Referring website addresses</li>
          </ul>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>We use the collected information to:</p>
          <ul>
            <li>Provide, maintain, and improve our services</li>
            <li>Process payments and issue receipts</li>
            <li>Communicate with you about your account and courses</li>
            <li>Send promotional materials, newsletters, or updates (you can opt-out at any time)</li>
            <li>Ensure compliance with legal obligations</li>
          </ul>
        </section>

        <section>
          <h2>4. Sharing Your Information</h2>
          <p>We do not sell or rent your personal information to third parties. However, we may share your information with:</p>
          <ul>
            <li><strong>Service Providers:</strong> Trusted third parties that help us operate our Website, such as payment processors, email service providers, and analytics providers.</li>
            <li><strong>Legal Authorities:</strong> When required to comply with the law or legal processes.</li>
          </ul>
        </section>

        <section>
          <h2>5. Cookies and Tracking Technologies</h2>
          <p>We use cookies and similar technologies to enhance your experience on our Website. Cookies help us analyze traffic, remember your preferences, and improve the functionality of our Website. You can disable cookies through your browser settings, but this may affect the performance of the Website.</p>
        </section>

        <section>
          <h2>6. Data Security</h2>
          <p>We take reasonable precautions to protect your personal information. Our Website uses SSL encryption, secure payment gateways, and other security measures to safeguard your data. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
        </section>

        <section>
          <h2>7. Third-Party Links</h2>
          <p>Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. Please review their privacy policies before providing them with any personal information.</p>
        </section>

        <section>
          <h2>8. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access the personal information we hold about you</li>
            <li>Request corrections or updates to your information</li>
            <li>Opt-out of receiving promotional communications</li>
            <li>Request deletion of your personal information (subject to legal and contractual obligations)</li>
          </ul>
          <p>To exercise these rights, please contact us at <a href="mailto:codetocrafts@gmail.com">codetocrafts@gmail.com</a>.</p>
        </section>

        <section>
          <h2>9. Children's Privacy</h2>
          <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
        </section>

        <section>
          <h2>10. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. The revised version will be effective as of the "Effective Date" stated at the top. We encourage you to review this Privacy Policy periodically to stay informed about how we protect your information.</p>
        </section>

        <section>
          <h2>11. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or how your information is handled, please contact us at:</p>
          <p><strong>By email:</strong> <a href="mailto:codetocrafts@gmail.com">codetocrafts@gmail.com</a></p>
        </section>
      </main>

      <footer style={{ marginTop: "20px", borderTop: "1px solid #ccc", paddingTop: "10px" }}>
        <p>&copy; 2025 CodeToCraft.tech. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
