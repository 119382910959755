import React from 'react';
import { Box } from '@mui/material';
import { ParallaxProvider } from 'react-scroll-parallax';
import HeroSection from './HeroSection';
import ImageSection from './ImageSection';
import PhilosophySection from './PhilosophySection';
import WhatWeOfferSection from './Whatweoffer';


const LandingPage = () => {
  return (
    <ParallaxProvider>
      <Box
        sx={{
          backgroundColor: 'black',
          minHeight: '100vh',
          color: 'white',
          padding: '10px',
          overflow: 'hidden',
        }}
      >
        <HeroSection />
        <ImageSection />
        <WhatWeOfferSection />
        <PhilosophySection />
        
      </Box>
    </ParallaxProvider>
  );
};

export default LandingPage;
