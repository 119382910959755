import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useParallax } from 'react-scroll-parallax';
import '../../App.css'; // Add this for custom styles

const HeroSection = () => {
  const { ref } = useParallax({ speed: -30 });

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh',
        textAlign: 'center',
        background: 'black',
      }}
    >
      <Typography
          variant="h2"
          sx={{
            fontFamily: "'Roboto', sans-serif", // Clean modern font
            fontWeight: '900',
            letterSpacing: 3,
            color: 'white',
            position: 'relative',
            textTransform: 'uppercase',
            fontSize: {
              xs: '3rem',
              sm: '4.5rem',
              md: '6rem',
            },
            textAlign: 'center',
          }}
          className="glitch-container"
        >
          <span className="glitch-layer glitch-layer-1">Learn Code Craft</span>
          <span className="glitch-layer glitch-layer-2">Learn Code Craft</span>
          <span className="glitch-layer glitch-layer-3">Learn Code Craft</span>
          Learn Code Craft
        </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 3,
          px: 4,
          py: 2,
          fontSize: { xs: '0.9rem', sm: '1rem' },
          backgroundColor: 'white',
          color: 'black',
          '&:hover': {
            backgroundColor: '#f1f1f1',
          },
        }}
        onClick={() => (window.location.href = '/courses')}
      >
        Explore Courses
      </Button>
    </Box>
  );
};

export default HeroSection;
