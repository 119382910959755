import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import ContactPage from './components/ContactPage';
import CoursesPage from './components/CoursesPage';
import AccountPage from './components/AccountPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions'
import { Box } from '@mui/material';
import './style.css';
import RegistrationPage from './components/RegistrationPage';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/courses" element={<CoursesPage />} />
            <Route path="/registration/:courseId" element={<RegistrationPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            {/* <Route path="/about" element={<AboutUs />} /> */}
            {/* <Route path="/privacy" element={<PrivacyPolicy />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/404" element={<PageNotFound />} />

          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;

