import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { useParallax } from 'react-scroll-parallax';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const WhatWeOfferSection = () => {
  const parallaxRef = useParallax({ speed: -10 }); // Adjust speed for parallax effect

  const offerings = [
    {
      icon: <CodeIcon fontSize="large" sx={{ color: '#FF4081' }} />,
      title: 'Hands-On Coding',
      description: 'Learn by doing with our interactive coding exercises and real-world projects.',
    },
    {
      icon: <SchoolIcon fontSize="large" sx={{ color: '#FF4081' }} />,
      title: 'Expert-Led Sessions',
      description: 'Join live sessions hosted by industry experts to boost your knowledge.',
    },
    {
      icon: <SupportAgentIcon fontSize="large" sx={{ color: '#FF4081' }} />,
      title: 'Doubt Solving Sessions',
      description: 'Get your doubts resolved with our discord community support.',
    },
  ];

  return (
    <Box
      ref={parallaxRef.ref} // Attach parallax effect
      sx={{
        padding: '50px 20px',
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        position: 'relative',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 'bold',
          marginBottom: '30px',
          textTransform: 'uppercase',
          letterSpacing: 2
        }}
      >
        What We Offer?
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {offerings.map((offer, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                backgroundColor: '#121212',
                color: 'white',
                boxShadow: '0 4px 20px rgba(255, 64, 129, 0.2)',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                {offer.icon}
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold',
                    marginTop: '15px',
                    textTransform: 'uppercase',
                  }}
                >
                  {offer.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginTop: '10px', color: 'rgba(255, 255, 255, 0.7)' }}
                >
                  {offer.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhatWeOfferSection;
