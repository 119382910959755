import axios from 'axios';
import config from '../config/config.json';

const apiUrl = config.apiBaseUrl;

// Set the base URL for all API requests
const apiClient = axios.create({
    baseURL: apiUrl,
    timeout: 3000, // Timeout after 10 seconds
    headers: {
        'Content-Type': 'application/json',
    },
});

// API methods
export const loginWithGoogle = async (googleIdToken) => {
    try {
        const response = await apiClient.post('/api/auth/google', {
            "idToken": googleIdToken,
          });
        return response; // Return the response data
    } catch (error) {
        console.error('Error logging in with Google:', error);
        throw error; // Propagate error for handling in components
    }
};

export const fetchUserDetails = async () => {
    try {
        const response = await apiClient.get('/user/details');
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};
export const fetchCourses = async () => {
    try {
        const response = await apiClient.get('/api/courses');
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

export const fetchCourseDetails = async (courseId) => {
    try {
        const response = await apiClient.get('/api/courses/'+courseId);
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};


// Add more API methods as needed
