import { color } from "framer-motion";
import React from "react";

const TermsAndConditions = () => {
    const containerStyle = {
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        maxWidth: "800px",
        margin: "0 auto",
        color: 'white'
        // textAlign: "justify", // Ensures paragraphs are evenly aligned on both sides
      };
  return (
    <div style={containerStyle}>
      <header>
        <h1>Terms and Conditions for codetocraft.tech</h1>
      </header>
      <main>
        <section>
          <h2>Refund Policy</h2>
          <p>
            All payments made for courses, subscriptions, or any other services offered by codetocraft.tech
            are non-refundable. If you choose to cancel your subscription or service after payment, no
            refunds will be provided.
          </p>
        </section>

        <section>
          <h2>Communication Consent</h2>
          <p>
            As of December 10, 2024, codetocraft.tech does not send newsletters, promotional emails, or
            similar communications. However, in the future, we may introduce such content. By using our
            services, you consent to receive updates, newsletters, promotional materials, and other
            messages from us. You can opt out at any time by clicking the unsubscribe link in our emails
            or by contacting us directly at{" "}
            <a href="mailto:codetocrafts@gmail.com">codetocrafts@gmail.com</a>.
          </p>
        </section>

        <section>
          <h2>Account Registration</h2>
          <p>
            By creating an account on codetocraft.tech, you confirm that:
            <ol>
              <li>You are at least 13 years old.</li>
              <li>The information you provide during registration is accurate, complete, and up-to-date.</li>
            </ol>
            You are responsible for maintaining the confidentiality of your account credentials and for any
            activity that occurs under your account.
          </p>
        </section>

        <section>
          <h2>Account Suspension and Termination</h2>
          <p>
            codetocraft.tech reserves the right to suspend or terminate your account at our discretion,
            without notice, for reasons including, but not limited to:
          </p>
          <ul>
            <li>Breach of these Terms and Conditions.</li>
            <li>Fraudulent activity.</li>
            <li>Misuse of the platform or violation of community guidelines.</li>
          </ul>
          <p>
            Suspended or terminated accounts may lose access to purchased services or courses without a
            refund.
          </p>
        </section>

        <section>
          <h2>Service Interruptions</h2>
          <p>
            We strive to provide seamless access to codetocraft.tech. However, there may be occasional
            interruptions due to maintenance, updates, or unavoidable technical issues. This may include
            temporary restrictions for registered or paid users.
          </p>
        </section>

        <section>
          <h2>Legal Actions</h2>
          <p>
            codetocraft.tech reserves the right to take legal action against accounts found in serious
            violation of these Terms and Conditions. This includes, but is not limited to, cases of fraud,
            abuse, or activities that harm our platform, services, or other users.
          </p>
        </section>

        <section>
          <h2>Agreement to Terms</h2>
          <p>
            By accessing or using codetocraft.tech, you agree to be bound by these Terms and Conditions. If
            you do not agree to any part of these Terms, please discontinue use of the site immediately.
          </p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            If you have questions or concerns about these Terms and Conditions, please contact us:
            <br />
            <strong>By email:</strong>{" "}
            <a href="mailto:codetocrafts@gmail.com">codetocrafts@gmail.com</a>
          </p>
        </section>
      </main>
      <footer style={{ marginTop: "20px", borderTop: "1px solid #ccc", paddingTop: "10px" }}>
        <p>&copy; 2025 codetocraft.tech. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
