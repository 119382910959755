import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const ContactInfo = () => {
  return (
    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
     background:'black', 
     width:'100%'
     }}>
      <Box sx={{
        textAlign: 'center',
        p: 3,
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: 2,
        backgroundColor: '#f9f9f9',
        width: '100%',
      }}>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
          Contact us via:
        </Typography>
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          Email: <a href="mailto:codetocrafts@gmail.com">codetocrafts@gmail.com</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default ContactInfo;
